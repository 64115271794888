import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

import Hero from "../../components/Hero";

import Tabs from "../../components/Tabs";
import Tab from "../../components/Tabs/Tab";
import TabContent from "../../components/Tabs/TabContent";
import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";
import BackgroundSpacer from "../../components/BackgroundSpacer";

import seoData from "../../seo/seoData";
import { ROUTE_WEBSITE_DOMAIN } from "../../routes";
import AccordionItem from "../../components/Accordion/AccordionItem";
import OtherLegalPageDetailsMk from "./OtherLegalPageDetails-MK";
import OtherLegalPageDetailsEn from "./OtherLegalPageDetails-EN";

const StyledLegalWrapper = styled.div`
  margin-top: ${props => (props.isMobile ? "-22px" : "-22px")};
`;

const StyledContent = styled.div`
  padding-top: 70px;
  padding-bottom: 150px;
`;

const StyledTitle = styled.h2`
  margin: 0;
  margin-bottom: 18px;
  font-size: 28px;
  line-height: 1.33;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
`;

const StyledSectionTitle = styled.h2`
  margin: 0;
  margin-bottom: 18px;
  font-size: 38px;
  line-height: 1.33;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
`;
const StyledArticle = styled.span`
  display: inline-block;
  padding: 8px 19px;
  border: 1px solid #d8dde6;
  margin-top: 10px;
  border-radius: 3px;
  & a {
    font-weight: 600;
    font-size: 14px;
  }
`;
const StyledDescription = styled(SectionDescription)`
  margin-bottom: 50px;
`;

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: ${props => (props.isMobile ? "0" : "0")};
`;

class OtherLegalPage extends React.Component {
  render() {
    const items = ['definitions', 'licniPodatoci']
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>{seoData[activeLanguage.code].other_legal.title}</title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].other_legal.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].other_legal.meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].other_legal.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].other_legal.meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_control-panel.png`}
                />
                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].other_legal.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].other_legal.meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_control-panel.png`}
                />
              </Helmet>
            )}
            <Hero
              title={translate("other.legal.mkhost-legal")}
              backgroundImage="/assets/backgrounds/bg-3-1.svg"
              backgroundPosition="bottom"
              backgroundSize={isMobileOnly && "cover"}
              paddingBottom={isMobileOnly && "50px"}
              subtitle={() => (
                <span>
                  {translate(
                    "other.legal.we-value-your-privacy-find-out-more-about-our-privacy"
                  )}
                </span>
              )}
            >
              <StyledBackgroundSpacer height="20px" />
            </Hero>

            <StyledLegalWrapper className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="row">
                  <div className="container">
                    <Tabs
                      defaultTab="privacy-policy"
                      renderTabs={(active, onSelect) => [
                        <Tab
                          key="tab-privacy-policy"
                          label="privacy-policy"
                          active={active}
                          onSelect={onSelect}
                        >
                          {translate("other.legal.privacy-policy")}
                        </Tab>,
                        <Tab
                          key="tab-terms"
                          label="terms"
                          active={active}
                          onSelect={onSelect}
                        >
                          {translate("other.legal.terms-of-service")}
                        </Tab>,
                        <Tab
                            key="tab-cookies"
                            label="cookies"
                            active={active}
                            onSelect={onSelect}
                        >
                          {translate("other.legal.cookies-policy")}
                        </Tab>,
                        <Tab
                          key="tab-rules"
                          label="rules"
                          active={active}
                          onSelect={onSelect}
                        >
                          {translate("other.legal.rules-registration")}
                        </Tab>
                      ]}
                      renderTabContent={active => (
                        <React.Fragment>
                          <TabContent label="privacy-policy" active={active}>
                            <StyledContent>
                              <StyledSectionTitle>
                                {translate("privacy-policy")}
                              </StyledSectionTitle>
                              <StyledDescription>
                                {translate(
                                  "other.legal.the-authorized-legal-entity-behind-this-privacy-policy-is-mkhost-dooel"
                                )}
                                .
                              </StyledDescription>
                              <StyledTitle>
                                {translate("other.legal.general-provisions")}
                              </StyledTitle>
                              <StyledDescription>
                                {translate(
                                  "other.legal.mkhost-will-not-sell-your-submitted-information"
                                )}
                                .
                              </StyledDescription>

                              <SectionDescription>
                                {translate(
                                  "other.legal.mkhost-does-not-sell-or-rent"
                                )}
                                .
                              </SectionDescription>

                              <SectionDescription>
                                {translate(
                                  "other.legal.the-registration-form-on-our-site-during-order-performance"
                                )}
                                .
                              </SectionDescription>

                              <SectionDescription>
                                {translate(
                                  "other.legal.this-site-contains-links-to-other-sites"
                                )}
                                .
                              </SectionDescription>

                              <SectionDescription>
                                {translate(
                                  "other.legal.the-data-collected-through-mkhost-are-used"
                                )}
                                .
                              </SectionDescription>

                              <StyledDescription>
                                {translate(
                                  "other.legal.in-case-of-a-criminal-investigation"
                                )}
                                .
                              </StyledDescription>
                              <div className="row">

                                {activeLanguage && activeLanguage.code === 'mk' && (
                                  <OtherLegalPageDetailsMk/>)
                                }
                                {activeLanguage && activeLanguage.code === 'en' && (
                                  <OtherLegalPageDetailsEn/>
                                )
                                }
                              </div>
                            </StyledContent>
                          </TabContent>
                          <TabContent label="terms" active={active}>
                            <StyledContent>
                              <SectionTitle>
                                {translate("other.legal.terms-of-service")}
                              </SectionTitle>
                              <StyledDescription>
                                {translate(
                                  "other.legal.with-any-use-of-services-provided-by-mkhost"
                                )}
                                .
                              </StyledDescription>
                              <StyledTitle>
                                {translate(
                                  "other.legal.short-description-of-terms"
                                )}
                              </StyledTitle>
                              <SectionDescription>
                                1.{" "}
                                {translate(
                                  "other.legal.any-kind-of-spamming-is-prohibited"
                                )}

                              </SectionDescription>

                              <SectionDescription>
                                2.{" "}
                                {translate(
                                  "other.legal.mkhost-services-must-not-be-exploited"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                3.{" "}
                                {translate(
                                  "other.legal.pornographic-contents-are-permitted"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                4.{" "}
                                {translate(
                                  "other.legal.speech-language-displayed-on-websites-or-domains"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                5.{" "}
                                {translate(
                                  "other.legal.any-distribution-of-material-that-violates-copyrights"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                6.
                                {translate(
                                  "other.legal.all-users-if-considered-as-mkhost-service-abusers"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                7.{" "}
                                {translate(
                                  "other.legal.mkhost-reserves-the-right"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                8.{" "}
                                {translate(
                                  "other.legal.mkhost-s-clients-agree-that-they"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                9.{" "}
                                {translate(
                                    "other.legal.short-1"
                                )}
                                .
                              </SectionDescription>

                              <StyledTitle>
                                {translate(
                                  "other.legal.detail-condition-description"
                                )}
                              </StyledTitle>
                              <SectionDescription>
                                1.{" "}
                                {translate(
                                  "other.legal.mkhost-reserves-the-right-without-prior-notice-to-modify"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                2.{" "}
                                {translate(
                                  "other.legal.mkhost-has-the-right-without-prior-notice-to-suspend-limit-or-restrict"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                3.{" "}
                                {translate(
                                  "other.legal.you-agree-in-the-process-of-initial-and-all-other"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                4.{" "}
                                {translate(
                                  "other.legal.after-debt-payment-for-using-the-services"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                5.{" "}
                                {translate(
                                  "other.legal.depending-on-delivered-or-contracted-services-enabled-for-use"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                6.{" "}
                                {translate(
                                  "other.legal.mkhost-agrees-to-accept-pornographic-contents"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                7.{" "}
                                {translate(
                                  "other.legal.you-as-a-client-of-mkhost-services-also-agree"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                8.{" "}
                                {translate(
                                  "other.legal.you-commit-to-take-into-consideration-local"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                9.{" "}
                                {translate(
                                  "other.legal.mkhost-also-reserves-the-right-to-take-appropriate-measures"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                10.{" "}
                                {translate(
                                  "other.legal.you-also-agree-that-you-will-not-threaten-or-collect"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                11.{" "}
                                {translate(
                                  "other.legal.if-mkhost-services-are-used-to-collect"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                12.{" "}
                                {translate(
                                  "other.legal.you-agree-not-to-use-the-services-for-storing"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                13.{" "}
                                {translate(
                                  "other.legal.you-agree-that-you-won-t-try-an-illegal-access"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                14.{" "}
                                {translate(
                                  "other.legal.you-by-using-mkhost-services-agree-to-post-contents"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                15.{" "}
                                {translate(
                                  "other.legal.you-agree-that-you-won-t-use-mkhost-services-for-distribution"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                16.{" "}
                                {translate(
                                  "other.legal.mkhost-reserves-the-right-to-block"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                17.{" "}
                                {translate(
                                  "other.legal.you-commit-that-you-won-t-use-mkhost-services"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                18.{" "}
                                {translate(
                                  "other.legal.mkhost-in-order-to-provide-quality-services"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                19.{" "}
                                {translate(
                                  "other.legal.using-mkhost-hosting-services-for-irc"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                20.{" "}
                                {translate(
                                  "other.legal.by-using-mkhost-services"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                21.{" "}
                                {translate(
                                  "other.legal.after-the-expiry-of-the-time-period-for-using-mkhost-services"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                22.{" "}
                                {translate(
                                  "other.legal.for-any-damage-to-your-business"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                23.{" "}
                                {translate(
                                  "other.legal.after-shoping-agreeement"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                24.{" "}
                                {translate(
                                  "other.legal.mkhost-dont-guarantee-your-content"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                25.{" "}
                                {translate(
                                  "other.legal.after-client-request-mkhost-will-provide"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                26.{" "}
                                {translate(
                                  "other.legal.in-case-of-terminating-hosting-services"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                27.{" "}
                                {translate(
                                  "other.legal.if-case-of-paying-debts"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                28.{" "}
                                {translate(
                                  "other.legal.mkhost-allows-selective-return-of-certain-files"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                29.{" "}
                                {translate(
                                  "other.legal.mkhost-guarantees-two-free-returns"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                30.{" "}
                                {translate(
                                  "other.legal.discounts-for-the-purchase-renewal-of-a-particular-service-can-not-be-combined"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                31.{" "}
                                {translate(
                                  "other.legal.if-the-hosting-for-any-package-is-leased-on-a-monthly-basis"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                32.{" "}
                                {translate(
                                  "other.legal.the-maximum-number-of-emails-sent"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                {translate(
                                  "other.legal.for-any-questions-or-information-concerning"
                                )}
                                .
                              </SectionDescription>
                            </StyledContent>
                          </TabContent>
                          <TabContent label="cookies" active={active}>
                            <StyledContent>
                              <SectionTitle>
                                {translate("other.legal.cookie-policy-title")}
                              </SectionTitle>
                              <StyledTitle>
                                {translate(
                                    "other.legal.what-are-cookies"
                                )}

                              </StyledTitle>
                              <SectionDescription>
                                {translate(
                                    "other.legal.cookies-desc"
                                )}
                              </SectionDescription>
                              <SectionDescription>

                                {translate(
                                    "other.legal.cookies-desc-continue"
                                )}

                              </SectionDescription>

                              <SectionDescription>

                                {translate(
                                    "other.legal.cookies-time-wrap"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                - {" "}
                                {translate(
                                    "other.legal.cookie-rule-num-one"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                - {" "}
                                {translate(
                                    "other.legal.cookie-rule-num-two"
                                )}

                              </SectionDescription>
                              <SectionDescription>

                                {translate(
                                    "other.legal.according-to-the-domain"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                - {" "}
                                {translate(
                                    "other.legal.according-to-the-domain-rule-one"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                - {" "}
                                {translate(
                                    "other.legal.according-to-the-domain-rule-two"
                                )}

                              </SectionDescription>
                              <SectionDescription>

                                {translate(
                                    "other.legal.info-about-the-cookies"
                                )}

                              </SectionDescription>

                              <StyledTitle>
                                {translate(
                                    "other.legal.what-do-cookies"
                                )}
                              </StyledTitle>
                              <SectionDescription>

                                {translate(
                                    "other.legal.what-do-cookies-desc"
                                )}

                              </SectionDescription>
                              <StyledTitle>

                                {translate(
                                    "other.legal.can-you-delete-cookies"
                                )}

                              </StyledTitle>
                              <SectionDescription>

                                {translate(
                                    "other.legal.delete-cookies-desc"
                                )}

                              </SectionDescription>
                              <StyledTitle>

                                {translate(
                                    "other.legal.can-you-block-cookies"
                                )}

                              </StyledTitle>
                              <SectionDescription>

                                {translate(
                                    "other.legal.can-you-block-cookies-desc"
                                )}

                              </SectionDescription>
                              <StyledTitle>
                                {translate(
                                    "other.legal.cookies-setup"
                                )}

                              </StyledTitle>
                              <SectionDescription>
                                - {" "}
                                {translate(
                                    "other.legal.first-cookies"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                - {" "}
                                {translate(
                                    "other.legal.second-cookies"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                - {" "}
                                {translate(
                                    "other.legal.third-cookies"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                - {" "}
                                {translate(
                                    "other.legal.fourth-cookies"
                                )}

                              </SectionDescription>
                              <SectionDescription>

                                {translate(
                                    "other.legal.cookie-setup-desc"
                                )}

                              </SectionDescription>
                              <StyledTitle>

                                {translate(
                                    "other.legal.cookies-in-mkhost"
                                )}

                              </StyledTitle>
                              <SectionDescription>

                                {translate(
                                    "other.legal.other-cookies"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                - {" "}
                                {translate(
                                    "other.legal.awsalbcors"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                - {" "}
                                {translate(
                                    "other.legal.test-cookies"
                                )}

                              </SectionDescription>
                              <StyledTitle>

                                {translate(
                                    "other.legal.other-static-cookies"
                                )}

                              </StyledTitle>
                              <SectionDescription>
                               -{" "}
                                {translate(
                                    "other.legal.ga"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                -{" "}
                                {translate(
                                    "other.legal.ga-new"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                - {" "}
                                {translate(
                                    "other.legal.gat"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                -{" "}
                                {translate(
                                    "other.legal.gid"
                                )}

                              </SectionDescription>
                              <StyledTitle>
                                {translate(
                                    "other.legal.marketing-cookies-set-by-mkhost"
                                )}
                              </StyledTitle>

                              <SectionDescription>
                                -.{" "}
                                {translate(
                                    "other.legal.zlcmid"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                               -{" "}
                                {translate(
                                    "other.legal.fbp"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                -{" "}
                                {translate(
                                    "other.legal.gcl_au"
                                )}

                              </SectionDescription>
                              <StyledTitle>
                                {translate(
                                    "other.legal.marketing-cookies-set-by-third-parties"
                                )}

                              </StyledTitle>
                              <SectionDescription>
                                -{" "}
                                {translate(
                                    "other.legal.ads-ga-audiences"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                -{" "}
                                {translate(
                                    "other.legal.fr"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                -{" "}
                                {translate(
                                    "other.legal.IDE"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                -{" "}
                                {translate(
                                    "other.legal.paged-1p-user-list"
                                )}

                              </SectionDescription>
                              <SectionDescription>
                                -{" "}
                                {translate(
                                    "other.legal.tr"
                                )}

                              </SectionDescription>
                              <StyledTitle>
                                {translate(
                                    "other.legal.uncategorized-by-mkhost"
                                )}
                              </StyledTitle>
                              <SectionDescription>
                                -{" "}
                                {translate(
                                    "other.legal.cookies-uncategories-by mkhost"
                                )}

                              </SectionDescription>
                              <SectionDescription>

                                {translate(
                                    "other.legal.question-cookies-here"
                                )}
                               <a href="mailto:  info@mkhost.com.mk"> info@mkhost.com.mk</a>

                              </SectionDescription>

                            </StyledContent>
                          </TabContent>

                          <TabContent label="rules" active={active}>
                            <StyledContent>
                              <SectionTitle>
                                {translate("other.legal.rules-for-mk-domains")}
                              </SectionTitle>
                              <SectionDescription>
                                1.{" "}
                                {translate(
                                  "other.legal.right-for-registration-mk-domain"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                2.{" "}
                                {translate(
                                  "other.legal.right-for-registration-edu-domain"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                3.{" "}
                                {translate(
                                  "other.legal.right-for-registration-org-domain"
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                4.{" "}
                                {translate(
                                  "other.legal.right-for-registration-com-net-inf-domain"
                                )}
                                .
                              </SectionDescription>
                              <StyledTitle>
                                {translate(
                                  "other.legal.rule-for-international-domains"
                                )}
                              </StyledTitle>
                              <SectionDescription>
                                1.{" "}
                                {parse(
                                  translate(
                                    "other.legal.com-domains-can-register"
                                  )
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                2.{" "}
                                {parse(
                                  translate(
                                    "other.legal.eu-domains-can-register"
                                  )
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                3.{" "}
                                {parse(
                                  translate(
                                    "other.legal.de-domains-can-register"
                                  )
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                4.{" "}
                                {parse(
                                  translate(
                                    "other.legal.rs-domains-can-register"
                                  )
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                5.{" "}
                                {parse(
                                  translate(
                                    "other.legal.au-domains-can-register"
                                  )
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                6.{" "}
                                {parse(
                                  translate(
                                    "other.legal.ru-domains-can-register"
                                  )
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                7.{" "}
                                {parse(
                                  translate(
                                    "other.legal.uk-domains-can-register"
                                  )
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                8.{" "}
                                {parse(
                                  translate(
                                    "other.legal.biz-domains-can-register"
                                  )
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                9.{" "}
                                {parse(
                                  translate(
                                    "other.legal.mobi-domains-can-register"
                                  )
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                10.{" "}
                                {parse(
                                  translate(
                                    "other.legal.top-design-solutions-domains-can-register"
                                  )
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                11.{" "}
                                {parse(
                                  translate(
                                    "other.legal.ws-domains-can-register"
                                  )
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                12.{" "}
                                {parse(
                                  translate(
                                    "other.legal.ca-domains-can-register"
                                  )
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                13.{" "}
                                {parse(
                                  translate(
                                    "other.legal.es-domains-can-register"
                                  )
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                14.{" "}
                                {parse(
                                  translate(
                                    "other.legal.us-domains-can-register"
                                  )
                                )}
                                .
                              </SectionDescription>
                              <SectionDescription>
                                15.{" "}
                                {parse(
                                  translate(
                                    "other.legal.al-domains-can-register"
                                  )
                                )}
                                .
                              </SectionDescription>
                            </StyledContent>
                          </TabContent>
                        </React.Fragment>
                      )}
                    />
                  </div>
                </div>
              </div>
            </StyledLegalWrapper>
          </React.Fragment>
        )}
      </Translate>
    );
  }
}
export default OtherLegalPage;
