import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import KnowledgeBaseCategories from "../../KnowledgeBaseCategories";
import MostPopularArticles from "../../MostPopularArticles";
import SecondaryButton from "../../Buttons/SecondaryButton";


import withLoading from "../../Loading/WithLoading";
import ContentLoader from "../../Loading/ContentLoader";
import { ROUTE_EMAIL_TUTORIALS } from "../../../routes";

const KnowledgeBaseCategoriesWithLoading = withLoading(
  KnowledgeBaseCategories,
  ContentLoader
);
const MostPopularArticlesWithLoading = withLoading(
  MostPopularArticles,
  ContentLoader
);
const StyledKnowledgeBaseSection = styled.div`
  padding: 100px 0;
`;

const StyledLoader = styled.div`
  width: 100%;
  height: 400px;
  display: block;
  background-color: red;
`;

const StyledInfo = styled.div`
  color: ${props => props.theme.white};
  border-radius: 3px;
  padding: 30px;
  padding-right: 80px;
  margin-bottom: 30px;
  & .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.56;
    margin: 0;
  }

  & .description {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    margin-bottom: 40px;
  }
`;

const StyledBlueInfo = styled(StyledInfo)`
  background-color: ${props => props.theme.blueBase};

`;
const KnowledgeBaseSection = ({
  categories,
  mostPopularArticles,
  title,
  description,
  isLoading,
  voteArticle,
  isAuthenticated = false
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <StyledKnowledgeBaseSection className="row" isMobile={isMobileOnly}>


          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              <div className="container">

                <StyledBlueInfo>

                  <h3 className="title">
                    {translate(
                      "navigation.other.email-tutorials.title"
                    )}
                  </h3>
                  <p className="description">
                    {translate(
                      "navigation.other.email-tutorials.description"
                    )}

                  </p>
                  <a
                    href={ROUTE_EMAIL_TUTORIALS}

                    rel="noopener noreferrer"
                  >
                    <SecondaryButton size="l">
                      {translate("online-shop-page.viw-here")}
                    </SecondaryButton>
                  </a>
                </StyledBlueInfo>

                <div className="row">
                  <div className="col-12 col-sm-12 col-md-4">
                    <KnowledgeBaseCategoriesWithLoading
                      isLoading={isLoading}
                      title={translate("categories")}
                      categories={categories}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-8">
                    <MostPopularArticlesWithLoading
                      isLoading={isLoading}
                      title={title}
                      description={description}
                      articles={mostPopularArticles}
                      voteArticle={voteArticle}
                      isAuthenticated={isAuthenticated}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledKnowledgeBaseSection>
      )}
    </Translate>
  );
};

export default KnowledgeBaseSection;
